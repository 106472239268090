import xhr from './xhr/';
/**
 * 数据对比相关的 API
 */
class ComparisionService {
	// 预警管理首页 获取后台传过来的select配置项
	getUserList(params) {
		return xhr({
			method: 'get',
			url: '/member/query',
			params,
		});
	}

	// 获取比对结果
	getResults(params) {
		return xhr({
			method: 'get',
			url: '/member/compare',
			params,
		});
	}
}

// 实例化后导出，全局单例
export default new ComparisionService();
